<template>
   <section class="login-page">
      <div class="login-area">
         <div class="row align-items-center justify-content-center height-self-center">
            <div class="col-lg-10">
               <div class="auth-card">
                  <div class="card-body p-0">
                     <div class="d-flex align-items-center auth-content">
                        <div class="col-lg-12 align-self-center">
                           <div class="p-3">
                              <img class="logo" :src="logo">
                              <form @submit.prevent="onSubmit()">
                                 <div class="row">
                                    <div class="col-lg-12">
                                       <div class="floating-label form-group">
                                          <label>Email</label>
                                          <input v-model="email" class="floating-input form-control" type="email"
                                             placeholder="Email">
                                       </div>
                                    </div>
                                    <div class="col-lg-12">
                                       <div class="floating-label form-group">
                                          <label>Company ID</label>
                                          <input v-model="company" class="floating-input form-control" type="text"
                                             placeholder="Company ID">
                                       </div>
                                    </div>
                                    <div class="col-lg-12">
                                       <div class="floating-label form-group">
                                          <label>Password</label>
                                          <input v-model="password" class="floating-input form-control" type="password"
                                             placeholder="Password">
                                       </div>
                                    </div>
                                 </div>
                                 <button type="submit" class="btn btn-primary btn-block">Sign In</button>
                                 <p class="mt-3">
                                    Forgot your password? <router-link :to="{ name: 'auth.reset' }"
                                       class="text-primary">Reset</router-link>
                                 </p>
                              </form>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="hero-area">
         <div class="content">
            <h1 class="mb-5">Welcome to Omni Next</h1>
            <p>Welcome to your Omni Next portal, delivered by Omni Automotive. The Omni Next portal is here to help you to view and manage your online store orders in a purpose built solution specifically designed for the Aftermarket industry. If you have any questions, our team are here to help. Simply email support@omniautomotive.uk.
</p>
<button type="submit" class="btn btn-primary mt-3">Request Access</button>
         </div>
      </div>
   </section>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
   name: 'SignIn',
   data() {
      return {
         email: '',
         company: '',
         password: ''
      }
   },
   computed: {
    ...mapGetters({
        logo: 'logo'
    })
},
   methods: {
      onSubmit() {
         this.$store.dispatch('login', { email: this.email, company: this.company, password: this.password });
      }
   }
}
</script>
<style lang="scss">
.logo{
   max-width: 120px;
   margin-bottom: 30px;
}
.login-page {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   min-height: 100vh;
   width: 100%;

   .hero-area {
      width: 50%;
      background: #00112c;
      display: flex;
      justify-content: center;
      align-items: center;
      .content{
         padding: 40px;
         max-width: 800px;
         color: #fff;
         h1{
            color: #fff;
         }
      }
   }

   .login-area {
      display: flex;
      width: 50%;
      justify-content: center;
      align-items: center;
   }
.form-control{
 background: #f3f6f9;
}
}
</style>